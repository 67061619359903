<template>
  <section class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>OMS</el-breadcrumb-item>
           <el-breadcrumb-item>酒店管家</el-breadcrumb-item>
           <el-breadcrumb-item>工单管理</el-breadcrumb-item>
           <el-breadcrumb-item>计划任务</el-breadcrumb-item>
           <el-breadcrumb-item>{{action === 'add' ? '创建任务' : '编辑任务'}}</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
     <!-- 返回页头 -->
     <el-page-header @back="goBack" :content="action === 'add' ? '创建任务' : '编辑任务'"></el-page-header>
     <!-- 表单 -->
     <el-row class="cont-form-box">
        <el-form class="form-box">
           <el-form-item label="任务类型">
              <el-select v-model="taskType" placeholder="请选择" >
                 <el-option
                       v-for="(item, index) in dictData['task-type']"
                       :key="index"
                       :label="item"
                       :value="index">
                 </el-option>
              </el-select>
           </el-form-item>
           <el-form-item label="所属部门">
              <el-select @change="getStaff" v-model="departmentId"  placeholder="请选择">
                 <el-option
                       v-for="item in departments"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
                 </el-option>
              </el-select>
           </el-form-item>
           <el-form-item label="员工">
              <el-select @change="changeStaff" v-model="staff"  placeholder="请选择">
                 <el-option
                       v-for="item in staffList"
                       :key="item.id"
                       :label="item.nickName"
                       :value="item.nickName">
                 </el-option>
              </el-select>
           </el-form-item>
           <el-form-item label="联系方式">
              <el-input class="task" placeholder="请输入联系方式" maxlength = '11' v-model="employPhone"></el-input>
           </el-form-item>
           <el-form-item label="时间">
              <el-date-picker
                    v-model="timeSlot"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd">
              </el-date-picker>
           </el-form-item>
           <el-form-item label="房间">
              <el-button type="primary" @click="getRoomList">选择房间</el-button>
           </el-form-item>
           <el-form-item>
              <el-button class="m-right-30" type="primary" plain @click="handleReset" v-text="$t('msg.reset')">重置</el-button>
              <el-button type="primary" @click="checkTasks()" v-show="action === 'add'">创建任务</el-button>
              <el-button type="primary" @click="ChangesSave()" v-show="action === 'edit'">保存修改</el-button>
           </el-form-item>
        </el-form>
     </el-row>
     <el-col>
        <el-dialog title="房间列表" :visible.sync="showRooms">
           <el-col class="box">
              <div id="room" :class="oldhotel.includes(item.roomNo) ? 'active' : ''" v-for="(item,index) in roomList" :key="item.roomNo" @click="selectedRoom(item.roomNo,index)">
                 <div class="number">房间号：{{item.roomNo}}</div>
                 <div class="principal">负责人：{{staff}}</div>
                 <div class="png"><img src="@/assets/lALPBF8a7dc6YA7MyMzI_200_200.png"></div>
              </div>
           </el-col>
           <el-row class="affirm">
              <el-button class="bg-gradient" type="primary" @click="notarize()">确 定</el-button>
           </el-row>
        </el-dialog>
     </el-col>
     <!-- 提示是否覆盖 -->
     <el-col>
        <el-dialog title="此数据已创建是否覆盖" :visible.sync="showWorkPlan" width="60%">
           <el-table :data="workPlans" style="width: 100%">
              <el-table-column prop="employName" label="员工姓名" width="180"></el-table-column>
              <el-table-column prop="employDuty" label="员工职务" width="180"></el-table-column>
              <el-table-column prop="employOrgName" label="所属部门" width="180"></el-table-column>
              <el-table-column prop="employPhone" label="联系方式" width="180"></el-table-column>
              <el-table-column label="任务类型">
                 <template slot-scope="scope">{{scope.row.taskType | status}}</template>
              </el-table-column>
              <el-table-column prop="createTime" label="时间"></el-table-column>
           </el-table>
           <span slot="footer" class="dialog-footer">
          <el-button @click="showWorkPlan = false">取 消</el-button>
          <el-button type="primary" @click="saveTasks(true)">确 定</el-button>
        </span>
        </el-dialog>
     </el-col>
  </section>

</template>

<script>
   import { urlObj } from '@/api/interface'
   import { system } from '@/api/interface/system'
   import {getDict} from '@/common/js/common'
   import {mapState} from 'vuex'
   export default {
      data(){
         return{
            departments:[],        // 所属部门
            showRooms: false,      // 显示房间列表
            roomList: [],          // 房间列表
            workPlans:[],          // 工作计划列表
            showWorkPlan: false,   // 显示已存工作计划
            departmentId: '',      // 当前部门ID
            tenantId:'',           // 租户ID
            currStaff: {},         // 当前选中员工
            /* 表单字段 */
            taskType: '',          // 任务类型
            timeSlot: [],          // 任务时间段
            pickerOptions: {
               disabledDate(time) {    //日期不能往前选
                  return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
               },
            },
            /* 提示文本 */
            confirm: '确定',
            cancel: '取消',
            publish_success: '发布成功！',
            paymentMoney_no_null: '派单费用不能为空！',
            typeId_no_null: '分类不能为空！',
            title_no_null: '标题不能为空！',
            title:'',               //标题
            staff:'',               //员工
            staffList:[],           //所有员工
            position:'',             //职位
            Employeescoding:'',      //雇员编码
            orgId:'',                //部门编码
            orgName:'',              //雇员所在部门名称
            dialogTableVisible: false,
            apartment:[],            //所有房间管理
            gouzi:[],                //点击选中
            roomNo:[],               //房间号
            Splitroomnumber:'',       //分割过的房间号
            employPhone:'',           //手机号
            action: 'add',                 //路由
            oldhotel:[],               //修改显示旧数据房间号
            oldData:{},
            allrooms:[],                //酒店所有房间
            ids:''
         }
      },
      mounted() {
         getDict(['task-type'])
         this.hotelId = this.hotelInfo.id
         this.tenantId = JSON.parse(sessionStorage.getItem('userInfo')).tenantId || ''
         this.action = this.$route.query.action
         this.echoData()
         this.getStaff()
         this.getDepartment()
      },
      computed: {
         ...mapState(['hotelInfo', 'dictData'])
      },
      methods: {
        echoData(){
            if (this.action === 'edit'){
              this.oldData = JSON.parse(sessionStorage.getItem('taskInfo'))
               this.taskType = this.oldData.taskType,
               this.departmentId = this.oldData.employOrgName,
               this.staff = this.oldData.employName,
               this.employPhone = this.oldData.employPhone,
               this.timeSlot.push(this.oldData.createTime,this.oldData.updateTime)
               this.Splitroomnumber = this.oldData.handleRoom
               this.Employeescoding = this.oldData.employId
               this.position = this.oldData.employDuty
               this.departmentId = this.oldData.employOrgId
            }
          },
         // 获取所属部门列表
         getDepartment(){
            const url = system.org
            this.$axios.post(url, {level:'2',hotelId:this.hotelId}, 'json').then(res => {
               this.departments = res.children
            })
         },
         // 获取员工列表
         getStaff(){
            const param = {
               orgId: this.departmentId,
               companyId: this.tenantId,
            }
            const url = urlObj.user
            this.$axios.post(url, param, 'json').then(res => {
               let staffList = res.records
               this.staffList = staffList.map(item => {
                 this.Employeescoding = item.id
                this.position = item.duty
                this.orgName = item.orgName
                 return {id: item.id, nickName: item.nickName, }
               })

            })
         },
        // 重置表单
        handleReset(){
              this.taskType = '',
              this.departmentId = '',
              this.staff = '',
              this.employPhone = '',
              this.timeSlot = [],
              this.Splitroomnumber = ''

        },
         // 改变当前选中用户
         changeStaff(val){
            this.currStaff = this.staffList.filter(item => {
               return item.id === val
            })
         },
         // 选择房间
         getRoomList(){
            this.showRooms = true
            const param = {
               limit: '15',
               page: '1',
               hotelId: this.hotelId
            }
            const url = urlObj.roomPage
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.roomList = res.records
                  let datas = []
                  datas.push(this.oldData)
                  datas.forEach(item => {
                     this.oldhotel.push(item.handleRoom)
                     if (this.action === 'edit') {
                        this.roomList.forEach(fr => {
                           this.allrooms.push(fr.roomNo)
                        })
                        let arr = []
                        arr = this.oldhotel.join(',')
                        this.oldhotel = arr
                     }
                  })
               }
            })
         },
         // 选中当前房间
         selectedRoom(roomNo){
            if (this.oldhotel.includes(roomNo)){
               this.oldhotel = this.oldhotel.filter(item => {
                  return item !== roomNo
               })
               return
               }
            this.oldhotel.push(roomNo)

           this.roomNo.push(roomNo)
           let roomnumber = this.roomNo.join(',')
           this.Splitroomnumber = roomnumber
         },
         // 任务是否存在
         checkTasks(){
             // 表单验证
             if (!this.taskType) {
                 this.$alert('请选择任务类型', '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                 })
                 return
             }
             if (!this.departmentId) {
                 this.$alert('请选择所属部门', '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                 })
                 return
             }
             if (!this.staff) {
                 this.$alert('请选择员工', '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                 })
                 return
             }
             if (this.employPhone  == '') {
                 this.$alert('手机号不能为空', '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                 })
                 return;
             }
             if (this.employPhone.length < 11) {
                 this.$alert('请输入11位数手机号', '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                 })
                 return
             }


             if (this.timeSlot.length === 0) {
                 this.$alert('请选择创建时间', '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                 })
                 return
             }
             if (!this.Splitroomnumber) {
                 this.$alert('请选择房间', '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                 })
                 return
             }

            let param = {
               beginDate: this.timeSlot[0],       //开始时间
               endDate: this.timeSlot[1],
               employId: this.Employeescoding
            }
            const url = urlObj.workPlan
            this.$axios.post(url, param).then((res) => {
               this.workPlans = res.records
               // 显示已存在的工作计划
               if (this.workPlans.length !== 0) {
                  this.showWorkPlan = true
                  this.showWorkPlan = true
                  let  beencreated = []
                  this.workPlans.map(item=>{
                     beencreated.push(item.id)
                     this.ids = beencreated.join(',')
                  })
                  return
               }
               this.saveTasks()
            })
         },
         // 创建
         saveTasks(bool){
            const param = {
               employId: this.Employeescoding,  //雇员编码
               employDuty: this.position,       //雇员职位
               employName: this.staff,          //雇员姓名
               employOrgId: this.departmentId,  //部门编码
               employOrgName:this.orgName,      //雇员所在部门名称
               beginDate: this.timeSlot[0],     //开始时间
               endDate: this.timeSlot[1],       //结束时间
               hotelId:this.hotelId,            //酒店ID
               handleRoom:this.Splitroomnumber, //房间号
               employPhone:this.employPhone,    //联系方式
               taskType: this.taskType,         //任务类型
               coverTag:bool,
               ids:this.ids
            }
            const url = urlObj.scheduledtask
            this.$axios.post(url, param).then((res) => {
               this.apartment = res.records
                  this.$message({
                     message: '添加成功',
                     type: 'success'
                  });
               this.showWorkPlan = false
               this.goBack()
            })
         },
         //保存
         ChangesSave(){
            // 表单验证
            if (!this.taskType) {
               this.$alert('请选择任务类型', '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.departmentId) {
               this.$alert('请选择所属部门', '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.staff) {
               this.$alert('请选择员工', '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (this.employPhone  == '') {
               this.$alert('手机号不能为空', '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return;
            }
            if (this.employPhone.length < 11) {
               this.$alert('请输入11位数手机号', '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }

            if (this.timeSlot === null ) {
               this.$alert('请选择创建时间', '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.Splitroomnumber) {
               this.$alert('请选择房间', '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (this.taskType == '维修类'){
               this.taskType = 'MAINTENANCE_TYPE'
            }
            if (this.taskType == '保洁类'){
               this.taskType = 'CLEAN_TYPE'
            }
            const param = {
               employId: this.Employeescoding,  //雇员编码
               employDuty: this.position,       //雇员职位
               employName: this.staff,          //雇员姓名
               employOrgId: this.departmentId,  //部门编码
               employOrgName:this.orgName,      //雇员所在部门名称
               hotelId:this.hotelId,            //酒店ID
               handleRoom:this.Splitroomnumber, //房间号
               employPhone:this.employPhone,    //联系方式
               taskType: this.taskType,         //任务类型
               id : this.oldData.id
            }
            const url = urlObj.scheduledtask
            this.$axios.post(url, param).then((res) => {
               this.apartment = res.records
               this.$message({
                  message: '修改成功',
                  type: 'success'
               });
               this.goBack()
            })
         },
         //返回
         goBack(){
            this.$router.go(-1)
         },
         notarize(){
           this.showRooms = false
         }
      },
      watch: {
         hotelInfo: {
            handler(newVal, oldVal) {
               if (newVal.id !== oldVal.id && oldVal) {
                  this.hotelId = this.hotelInfo.id
                  this.echoData()
                  this.getStaff()
                  this.getDepartment()
               }
            },
         }
      },
      filters:{
         status(data){
            if (data === 'MAINTENANCE_TYPE'){
               return '维修类'
            }
            if (data === 'CLEAN_TYPE'){
               return '保洁类'
            }
         }
      }
   }

</script>

<style scoped lang="scss">

.checked{
  background: #eff4f7;
}
.flex-pic{
  display: flex;
}
.old-pic{
  margin-right: 10px;
  border: 1px #eeeeee solid;
  border-radius: 6px;
}

.cont{
  min-width: 1120px;
}
.el-row{
  line-height: 30px;
  white-space: nowrap;
}
.det-box{
  /* border: 1px solid red; */
  background: white;
  padding: 10px 20px;
  .box-next{
    width: 60%;
    margin: 0 auto 20px;
    /deep/ .el-input__inner{
      height: 40px;
      line-height: 40px;
    }
  }
  .next-button{
    text-align: center;
    .el-button{
      width: 120px;
    }
  }
  .box-info{
    width: 80%;
    margin: 0 auto;
    /deep/ .el-input__inner{
      height: 40px;
      line-height: 40px;
    }
    .rate-number{
      /deep/ .el-rate__icon{
        height: 40px;
        line-height: 40px;
        font-size: 30px;
      }
    }
  }
  .box_list{
    .el-col-4{
      height: 50px;
      line-height: 50px;
      padding-left: 15px;
      border: 1px solid rgba(204, 204, 204, 1);
      border-width: 0 1px 1px 0;
      &:nth-child(6n){
        border-right: 0;
      }
      &:nth-last-child(1),&:nth-last-child(2),&:nth-last-child(3),&:nth-last-child(4),&:nth-last-child(5),&:nth-last-child(6){
        border-bottom: 0;
      }
    }
    .box-inline{
      display: inline-block;
      cursor: pointer;
    }
  }
  .box-bg{
    background: rgba(204, 204, 204, 0.3);
    border-bottom: 1px solid rgba(204, 204, 204, 1);
  }
  .box-border{
    border: 1px solid rgba(204, 204, 204, 1);
  }
}
.det-box::after{
  content: "";
  display: block;
  clear: both;
}
.el-col-16{margin-top:0.4rem;}
.el-select{width: 260px}
/deep/.el-textarea__inner{width:260px;}

.goBack{background: white;}

.el-row .el-date-editor.el-input{width: 260px}
#room{width: 17%;height: auto; border: 1px solid #999; margin: 1.2%; min-height: 100px;}
.number{padding-left: 1rem; color: black;margin-top: 1rem;}
.principal{padding-left: 1rem;color: black; margin-top: 0.5rem; margin-bottom:1rem;}
.affirm{width: 100%;position: absolute;bottom: 10px ;left:46%;}
.box{width: 100%;position: relative; display: flex;flex-wrap:wrap;}
/deep/ .el-dialog{position: relative; height:auto; min-height:40rem;}
#room{position: relative;}
.png{position: absolute;right: -4%; bottom:-12%; display: none;}
.active .png{ display: block }
.png img{width: 50px;}
.task{width: 260px;}
.block .el-date-editor{width: 260px;}


</style>
